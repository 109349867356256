import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import {CompactButton} from "../components/button";
import { navigate } from "gatsby";
import * as R from 'ramda';
import queryString from "query-string";

const Register = () => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    // const { state: authState, register } = useAuth();
    const query =
        typeof window !== 'undefined' ?
        queryString.parse(window.location.search) : {};
    const callback: string = query?.callback?.toString() ?? "";

    return (
      <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
            }}>
                <div
                >
                    <h5>註冊</h5>
                    <form>
                        <label>
                            <p>名稱</p>
                            <input type="text" value={name} onChange={(e) => {
                                setName(e.target.value);
                            }}/>
                        </label>
                        <label>
                            <p>Email</p>
                            <input type="text" value={email} onChange={(e) => {
                                setEmail(e.target.value);
                            }}/>
                        </label>
                        <label>
                            <p>密碼</p>
                            <input type="password" value={password} onChange={(e) => {
                                setPassword(e.target.value);
                            }}/>
                        </label>
                        {/*{*/}
                        {/*    authState?.errorMessage && (*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                color: "red",*/}
                        {/*                fontSize: "0.8rem",*/}
                        {/*            }}*/}
                        {/*        >*/}

                        {/*            {authState.errorMessage}*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}

                        <div style={{ height: 16}} />
                        <CompactButton onClick={() => {
                        //    register(email, password, name);
                        }}>
                            註冊
                        </CompactButton>
                    </form>
                </div>
            </div>
        </div>
    );
}
export  default  Register;
